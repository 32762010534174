<div class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <div class="logo" [ngStyle]="{ 'background-image': 'url(assets/images/logos/NS-icon-dark.svg)' }"></div>

  <button mat-icon-button class="toggle-sidebar" (click)="toggleSidebarFolded()">
    <div class="double-arrow">
      <!-- We don't have any double arrow icons in fuse -->
      {{ isFolded() ? "»" : "«" }}
    </div>
  </button>
</div>

<div class="navbar-content-wrapper">
  <div class="navbar-content" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
    <div class="bwan-header">
      <a mat-icon-button class="bwan-header-back" href="/" *ngIf="_featureFlagService.get('SINGLE_UI')">
        <mat-icon>arrow_back</mat-icon>
      </a>
      <div class="bwan-header-title">
        Borderless<br />
        SD-WAN
      </div>
    </div>
    <fuse-navigation layout="vertical"></fuse-navigation>
  </div>
  <fuse-nav-vertical-footer />
</div>
