import { TranslateService } from "@ngx-translate/core";
import infmgmtintf from "@infiot/infmgmtintf";
import { SankeyNodes, SankeyLinks } from "@infiot-fuse/utilities/inf-graph-util";
import { Filter, ComparisonOperator } from "gqlv3/graphql";

export enum TransactionStatNodes {
  processOrDevice = "processOrDevice",
  queue = "queue",
  interface = "interface",
  peer = "peer",
  application = "application",
}

interface INodeDataTarget {
  targetCategory: string;
  targets: {
    targetNodeKey: string;
    weight: number;
  }[];
}

export interface INodeData extends SankeyNodes {
  filterKey: string;
  endNode?: boolean;
  target?: INodeDataTarget;
}

export const BE_ERROR_DATA_CONSTANT = "UNSPECIFIED";

export class TransactionSankeyGraphHelper {
  private categoriesAllUniqNodes: Record<string, Map<string, { label: string; value: string }>> = {};

  private nodesByCategories: Record<string, Map<string, INodeData>> = {};

  public EMPTY_PROCESS_LABEL: string;

  public EMPTY_DEVICE_LABEL: string;

  public EMPTY_PEER_LABEL: string;

  public EMPTY_INTERFACE_LABEL: string;

  public EMPTY_ISP_LABEL: string;

  public EMPTY_APPLICATION_LABEL: string;

  public EMPTY_PRIORITY_LABEL: string;

  public EMPTY_CLASS_LABEL: string;

  public GROUPED_PROCESS_LABEL: string;

  public GROUPED_DEVICE_LABEL: string;

  public GROUPED_QUEUE_LABEL: string;

  public GROUPED_INTERFACE_LABEL: string;

  public GROUPED_PEER_LABEL: string;

  public GROUPED_APPLICATION_LABEL: string;

  constructor(private _translate: TranslateService) {
    this.reset();
    // default labels for empty device, peer, isp, application
    this.EMPTY_PROCESS_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_UNKNOWN_PROCESS");

    this.EMPTY_DEVICE_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_UNKNOWN_DEVICE");

    this.EMPTY_PEER_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_UNKNOWN_PEER");

    this.EMPTY_INTERFACE_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_UNKNOWN_INTERFACE");

    this.EMPTY_ISP_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_UNKNOWN_ISP");

    this.EMPTY_APPLICATION_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_UNKNOWN_APPLICATION");

    this.EMPTY_PRIORITY_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_UNKNOWN_PRIORITY");

    this.EMPTY_CLASS_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_UNKNOWN_CLASS");

    // Labels to display when nodes are grouped
    this.GROUPED_PROCESS_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_GROUPED_PROCESS");

    this.GROUPED_DEVICE_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_GROUPED_DEVICE");

    this.GROUPED_QUEUE_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_GROUPED_QUEUE");

    this.GROUPED_INTERFACE_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_GROUPED_INTERFACE");

    this.GROUPED_PEER_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_GROUPED_PEER");

    this.GROUPED_APPLICATION_LABEL = this._translate.instant("IDS_SITE_MONITOR_TRANSACTION_GROUPED_APPLICATION");
  }

  private getNodesByCategory(category: string): INodeData[] {
    if (!(category in this.nodesByCategories)) {
      throw new Error(`Invalid category: ${category}`);
    }

    return Array.from(this.nodesByCategories[category].values());
  }

  private addLinkNode(links: SankeyLinks[], sourceNode: INodeData, targetNode: INodeData, weight: number): void {
    const currNodes = links.find((l) => l.source === sourceNode.name && l.target === targetNode.name);
    if (!currNodes) {
      links.push({
        source: sourceNode.name,
        target: targetNode.name,
        value: weight,
        color: targetNode.color,
      });
    }
  }

  /**
   * Node to be displayed if selected in filter or no filters for node category
   * @param node
   * @param filterValues
   * @returns
   */
  private isDisplayNode(node: INodeData, filterValues: Map<string, string[]> | undefined): boolean {
    if (filterValues?.has(node.category)) {
      return filterValues.get(node.category)!.includes(node.filterKey);
    }

    return true;
  }

  private generateLinkNode(
    currentNode: INodeData,
    filterValues: Map<string, string[]> | undefined,
    links: SankeyLinks[],
  ) {
    if (currentNode.endNode) {
      return true;
    }
    let isNodeAdded = false;
    currentNode.target?.targets.forEach((target) => {
      const targetNode = this.nodesByCategories[currentNode.target!.targetCategory!].get(target.targetNodeKey);
      if (targetNode && this.isDisplayNode(targetNode, filterValues)) {
        const isAddLink = this.generateLinkNode(targetNode, filterValues, links);
        if (isAddLink) {
          this.addLinkNode(links, currentNode, targetNode, target.weight);
        }
        isNodeAdded = isNodeAdded || isAddLink;
      }
    });

    return isNodeAdded;
  }

  /**
   * Generates links from the passed filters
   * @param filteredNodes
   */
  private generateLinks(filterValues: Map<string, string[]> | undefined): SankeyLinks[] {
    // since device is the start node, we start creating links from device
    let startNodes = this.getNodesByCategory(TransactionStatNodes.processOrDevice);
    if (filterValues?.has(TransactionStatNodes.processOrDevice)) {
      startNodes = startNodes.filter((startNode) =>
        filterValues.get(TransactionStatNodes.processOrDevice)?.includes(startNode.filterKey),
      );
    }

    const links: SankeyLinks[] = [];
    startNodes.forEach((startNode) => this.generateLinkNode(startNode, filterValues, links));

    return links;
  }

  public reset() {
    Object.values(TransactionStatNodes).forEach((category) => {
      this.categoriesAllUniqNodes[category] = new Map();
      this.nodesByCategories[category] = new Map();
    });
  }

  /** Add node in the category */
  public addNode(
    sankeyNode: SankeyNodes & {
      // used in cases when filter label is different than whats shown in sankey node
      filterValue?: string;
      filterLabel?: string;
      endNode?: boolean;
    },
  ): INodeData {
    const category = sankeyNode.category;
    if (!(category in this.nodesByCategories)) {
      throw new Error(`Invalid category: ${category}`);
    }

    const filterKey = sankeyNode.filterValue ?? sankeyNode.name;

    this.categoriesAllUniqNodes[category].set(filterKey, {
      label: sankeyNode.filterLabel ?? sankeyNode.title,
      value: filterKey,
    });
    const nodeKeyWithCategory = `${category}-${sankeyNode.name}`;
    const categoryMap = this.nodesByCategories[category];

    if (categoryMap.has(nodeKeyWithCategory)) {
      return categoryMap.get(nodeKeyWithCategory)!;
    }

    const node: INodeData = {
      ...sankeyNode,
      filterKey,
      name: nodeKeyWithCategory,
    };

    categoryMap.set(nodeKeyWithCategory, node);

    return node;
  }

  public getFilterOptions(category: string) {
    const categoryMap = this.categoriesAllUniqNodes[category];

    if (!categoryMap) {
      return [];
    }

    return Array.from(categoryMap.values())
      .filter((item) => item.label && item.value)
      .sort((a, b) => Intl.Collator().compare(a.label, b.label));
  }

  public getFilteredLinksAndNodes(filterValues: Map<string, string[]> | undefined) {
    const filteredLinks = this.generateLinks(filterValues);

    const uniqueNodes: Set<string> = new Set();
    filteredLinks.forEach((link) => {
      uniqueNodes.add(link.source);
      uniqueNodes.add(link.target);
    });

    const filteredNodes = Object.values(TransactionStatNodes).reduce(
      (nodesByCategory: Partial<Record<TransactionStatNodes, INodeData[]>>, category) => {
        nodesByCategory[category] = this.getNodesByCategory(category).filter((node) => uniqueNodes.has(node.name));

        return nodesByCategory;
      },
      {},
    );

    return {
      filteredLinks,
      filteredNodes,
    };
  }

  public checkDataForError(
    inData: string,
    defaultLabel: string,
  ): {
    value: string;
    label: string;
  } {
    if (!inData?.trim() || inData === infmgmtintf.BQ_ERROR_DATA_CONSTANT || inData === BE_ERROR_DATA_CONSTANT) {
      return {
        value: BE_ERROR_DATA_CONSTANT,
        label: defaultLabel,
      };
    }

    return {
      value: inData,
      label: inData,
    };
  }

  public getOtherNodeKeyLabelByCategory(isClient: boolean, category: TransactionStatNodes) {
    let otherNodeLabel = "";
    switch (category) {
      case TransactionStatNodes.processOrDevice:
        otherNodeLabel = isClient ? this.GROUPED_PROCESS_LABEL : this.GROUPED_DEVICE_LABEL;
        break;
      case TransactionStatNodes.queue:
        otherNodeLabel = this.GROUPED_QUEUE_LABEL;
        break;
      case TransactionStatNodes.interface:
        otherNodeLabel = this.GROUPED_INTERFACE_LABEL;
        break;
      case TransactionStatNodes.peer:
        otherNodeLabel = this.GROUPED_PEER_LABEL;
        break;
      case TransactionStatNodes.application:
        otherNodeLabel = this.GROUPED_APPLICATION_LABEL;
        break;
      default:
        throw new Error(`Invalid category: ${category}`);
    }

    return {
      name: `${category}-${otherNodeLabel}`,
      title: otherNodeLabel,
    };
  }

  // create filters for backend query

  /**
   * Filter condition when value is UNSPECIFIED
   * @param {string} property - target field key
   * @returns {Filter} - filter condition
   */
  private createUnspecifiedFilter(property: string): Filter {
    return {
      OR: [
        { filter: { operation: ComparisonOperator.IS_EMPTY, property } },
        { filter: { operation: ComparisonOperator.EQ, property, value: infmgmtintf.BQ_ERROR_DATA_CONSTANT } },
      ],
    };
  }

  private processQueueFilters(values: string[]): Filter[] {
    const OR: Filter[] = [];
    const priorityFieldKey = "priority";
    const classFieldKey = "class";
    for (const value of values) {
      const parts = value.split("-");
      if (parts.length === 2) {
        OR.push({
          AND: [
            parts[0] === BE_ERROR_DATA_CONSTANT
              ? this.createUnspecifiedFilter(priorityFieldKey)
              : { filter: { operation: ComparisonOperator.EQ, property: priorityFieldKey, value: parts[0] } },
            parts[1] === BE_ERROR_DATA_CONSTANT
              ? this.createUnspecifiedFilter(classFieldKey)
              : { filter: { operation: ComparisonOperator.EQ, property: classFieldKey, value: parts[1] } },
          ],
        });
      }
    }

    return OR;
  }

  public formFilters(filterValues: Map<string, string[]> | undefined): Filter | null {
    if (!filterValues?.size) {
      return null;
    }

    const AND: Filter[] = [];
    const propertyMapping: Record<string, string> = {
      processOrDevice: "name",
      interface: "interfaceName",
      peer: "peer",
      application: "applicationName",
    };

    for (const [category, values] of filterValues.entries()) {
      if (values.length === 0) {
        continue;
      }

      if (category === TransactionStatNodes.queue) {
        const OR = this.processQueueFilters(values);
        if (OR.length > 0) {
          AND.push({ OR });
        }
      } else {
        const mappedProperty = propertyMapping[category] || category;
        const normalValues = values.filter((value) => value !== BE_ERROR_DATA_CONSTANT);
        const hasUnspecified = values.includes(BE_ERROR_DATA_CONSTANT);

        if (hasUnspecified || normalValues.length > 0) {
          const OR: Filter[] = [];

          if (hasUnspecified) {
            OR.push(this.createUnspecifiedFilter(mappedProperty));
          }
          if (normalValues.length > 0) {
            OR.push({
              filter: {
                operation: normalValues.length > 1 ? ComparisonOperator.IN : ComparisonOperator.EQ,
                property: mappedProperty,
                value: normalValues.length > 1 ? normalValues : normalValues[0],
              },
            });
          }

          if (OR.length > 1) {
            AND.push({ OR });
          } else if (OR.length === 1) {
            AND.push(OR[0]); // If there's only one condition
          }
        }
      }
    }

    return { AND };
  }
}
