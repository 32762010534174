import _ from "lodash";
import * as infschemas from "@infiot/infschemas";
import { isNetworkInterface, isSubInterface, isUsbInterface } from "@infiot/infmgmtintf";
import { VnetIpsecTunnel } from "@infiot-fuse/services/inf-site-vpn-peers.service";
import { InfOverlayTagsService, SystemGeneratedTag } from "@infiot-fuse/services/inf-overlay-tags.service";
import { PathType } from "@policy/policy-details/policy-details-form";
import { OverlayTagType, WellKnownId } from "gqlv3/graphql";

export function getLegacyWanLinkTagForInterface(
  legacyInterfaceOverlay: infschemas.InfiotOverlay,
): SystemGeneratedTag | undefined {
  return InfOverlayTagsService.allSystemGeneratedTags?.find(
    (tag) => tag.type === OverlayTagType.WANLINK && tag.legacyTag === legacyInterfaceOverlay.ovly_tag,
  );
}

export function getDefaultWanLinkTagForInterface(): SystemGeneratedTag | undefined {
  return InfOverlayTagsService.allSystemGeneratedTags?.find(
    (tag) => tag.type === OverlayTagType.WANLINK && tag.wellKnownId === WellKnownId.WANLINK_WIRED,
  );
}

export function getDefaultOverlayTagForInterface(): SystemGeneratedTag | undefined {
  return InfOverlayTagsService.allSystemGeneratedTags?.find(
    (tag) => tag.type === OverlayTagType.OVERLAY && tag.wellKnownId === WellKnownId.OVERLAY_PUBLIC,
  );
}

export function getOverlayTagBasedOnLegacyWanTagForInterface(
  legacyInterfaceOverlay: infschemas.InfiotOverlay,
): SystemGeneratedTag | undefined {
  if (
    legacyInterfaceOverlay.ovly_tag === infschemas.OvlyTag.wired ||
    legacyInterfaceOverlay.ovly_tag === infschemas.OvlyTag.wireless
  ) {
    return getDefaultOverlayTagForInterface();
  }

  return InfOverlayTagsService.allSystemGeneratedTags?.find(
    (tag) => tag.type === OverlayTagType.OVERLAY && tag.wellKnownId === WellKnownId.OVERLAY_PRIVATE,
  );
}

export function getLegacyWanLinkTagForPolicy(legacyPath: PathType): SystemGeneratedTag | undefined {
  return InfOverlayTagsService.allSystemGeneratedTags?.find(
    (tag) => tag.type === OverlayTagType.WANLINK && tag.legacyTag === legacyPath.lnks_wan,
  );
}

export function getDefaultWanLinkTagForPolicy(): SystemGeneratedTag | undefined {
  return InfOverlayTagsService.allSystemGeneratedTags?.find(
    (tag) => tag.type === OverlayTagType.WANLINK && tag.wellKnownId === WellKnownId.WANLINK_PRIVATE,
  );
}

export function generateRandomUniqueIDString(): string {
  return `${(Math.random() + 1).toString(36).substring(7)}-${new Date().valueOf()}`;
}

export const maxNumberOfSubInterfaceSupported = 31;
export const maxNumberOfSecondaryIpAddressSupported = 31;

export const isInterfaceEligibleToHaveSubInterface = (intf: infschemas.Interface | undefined): boolean =>
  isNetworkInterface(intf) && intf?.intf_mode === infschemas.IntfMode.routed && !isUsbInterface(intf);

export const getNativeVlanFieldName = (intf: infschemas.Interface): string =>
  isSubInterface(intf) ? "intf_subif_type" : "intf_switch_type";

export const isInterfaceAssociatedWithTunnel = (
  segments: infschemas.VirtualNetworkSettings[],
  interfaceName: string,
): boolean =>
  segments
    .reduce((acc: VnetIpsecTunnel[], segment) => acc.concat(segment.vnet_ipsec_tunnels || []), [])
    .some((tunnel) => tunnel.intf_name === interfaceName);

export function assignIntfNameIdInClientIpPool(
  templateVirtualNetwork: infschemas.VirtualNetwork,
  gatewayVirtualNetwork: infschemas.VirtualNetwork,
) {
  templateVirtualNetwork.forEach((network, index) => {
    const templateIpPoolRange = network?.client_ip_pool?.ipv4_pool_range;
    if (templateIpPoolRange && templateIpPoolRange.length > 0) {
      templateIpPoolRange.forEach(({ intf_name_id }, poolIndex) => {
        if (intf_name_id) {
          const gatewayIpPoolRange = gatewayVirtualNetwork[index].client_ip_pool?.ipv4_pool_range;
          if (gatewayIpPoolRange && gatewayIpPoolRange.length > poolIndex) {
            gatewayIpPoolRange[poolIndex]["intf_name_id"] = intf_name_id;
          }
        }
      });
    }
  });

  return _.cloneDeep(gatewayVirtualNetwork);
}
