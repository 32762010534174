import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import * as rxjs from "rxjs";
import * as rxjsoperators from "rxjs/operators";

import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseConfigService } from "@fuse/services/config.service";
import { FeatureFlagService } from "app/feature-flag/feature-flag.service";

@Component({
  selector: "navbar-vertical-style-2",
  templateUrl: "./style-2.component.html",
  styleUrls: ["./style-2.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle2Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: rxjs.Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    public _featureFlagService: FeatureFlagService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new rxjs.Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, { static: true })
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(rxjsoperators.delay(500), rxjsoperators.takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        rxjsoperators.filter((value) => value !== null),
        rxjsoperators.takeUntil(this._unsubscribeAll),
      )
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });

    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(rxjsoperators.takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.toggleFold();
  }

  /**
   * Is the sidebar folded
   */
  isFolded(): boolean {
    return !!this._fuseSidebarService.getSidebar()?.folded;
  }
}
